<template>
  <div style="height: 8vh"></div>

  <div id="content" style="margin-left: 10vw; margin-right: 10vw;">
    <span style="font-size: 1.25rem; white-space: nowrap">下载地址：</span>
    <div style="margin-right: 1rem; flex: 1; min-width: 150px">
      <el-input v-model="input" size="large" @keydown.enter="btnAddTask" />
    </div>
    <el-button @click="btnAddTask" size="large" :disabled="input.trim() === ''">下载</el-button>
  </div>

  <div class="lfont" style="margin-bottom: 3vh; margin-top: 2vh">状态： {{ state }}</div>

  <div v-if="state != '无任务'" class="flexcenter" style="width: 90vw">
    <el-progress :percentage="percentage" :stroke-width="20"/>
  </div>

  <div v-if="state != '无任务'" class="flexcenter"
       style="font-size: 1.15rem;width: 90vw; margin-top: 5vh; display: flex; align-items: flex-start; flex-direction: column">
    <div v-if="state == '下载中' || state == '合并中'">
      当前下载：<span v-if="filename !== ''">{{ filename }}&nbsp; </span> <span v-if="curl.startsWith('http') && curl !== filename">{{curl}}</span>
    </div>
    <div style="margin-top: 3vh">
      文件队列：{{ task }}
    </div>
  </div>

</template>

<script>
import {ElMessage} from 'element-plus'

export default {
  name: 'HomeView',
  data() {
    return {
      input: '',
      state: '无任务',
      percentage: 0,
      filename: '',
      task: '',
      curl: '',
      websock: null,
      wsHeartflag: false,
      reconnectTime: 0,
    }
  },
  created() {
    this.initWebSocket()
  },
  unmounted() {
    this.websock.close()
  },
  methods: {
    getWSData(data) {
      if (data.state == 'free') {
        this.state = '无任务'
        this.curl = ''
        this.task = ''
        this.percentage = 0
        this.filename = ''
      } else if (data.state == 'downloading') {
        this.state = '下载中'
        if (data.task.length == 1)
          this.task = '无'
        else {
          let arr = data.task
          arr.shift()
          let x = ''
          arr.forEach(i => {
            x += i + ', '
          })
          x = x.substring(0, x.length - 2);
          this.task = x
        }
        this.curl = data.curl
        if (data.filename)
          this.filename = data.filename
        else
          this.filename = data.curl
        if (data.type != 'mp4')
          this.percentage = Math.floor(Number(data.progress) * 90)
        else
          this.percentage = Math.floor(Number(data.progress) * 100)
      } else if (data.state == 'waiting') {
        this.state = '任务间隔等待'
        this.curl = ''
        this.percentage = 0
        this.filename = ''

        let arr = data.task
        let x = ''
        arr.forEach(i => {
          x += i + ', '
        })
        x = x.substring(0, x.length - 2);
        this.task = x
      } else if (data.state == 'merging') {
        this.percentage = 90
        this.state = '合并中'
        this.filename = data.filename
      }
    },
    //添加任务
    btnAddTask() {
      if (this.input === '') return
      let cUrl = '/api/add'
      this.$axios.post(cUrl, {url: this.input})
          .then(res => {
            if (res.data.msg === 'success') {
              ElMessage({
                message: '添加成功',
                type: 'success',
              })
              this.input = ''
            } else {
              ElMessage.error(JSON.stringify(res.data.msg))
            }
          })
          .catch(err => {
            ElMessage.error(`任务添加失败：服务器未正确响应: ${JSON.stringify(err.message)}`)
          })
    },
    //ws相关
    initWebSocket() {
      //服务器上打开
      const wsUri = `${location.protocol === 'https:' ? 'wss' : 'ws'}://${location.host}/ws`
      //本地测试打开
      // const wsUri = `ws://127.0.0.1:8988/ws`
      this.websock = new WebSocket(wsUri)
      this.websock.onmessage = this.websocketonmessage
      this.websock.onopen = this.websocketonopen
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    //ws开启
    websocketonopen() { //连接建立之后执行send方法发送数据
      this.wsHeartflag = true;
      this.reconnectTime = 0;
      this.websocketsend('奋进号，启动！');

      this.timer = window.setInterval(() => {
        setTimeout(() => {
          this.websocketsend('奋进号，加油！');
        }, 0)
      }, 1500)
    },
    //ws连接错误
    websocketonerror() {//连接建立失败重连
      console.log('ws失败重连')
      clearTimeout(this.wsHeart);
      clearTimeout(this.timer);
      this.wsHeartflag = false;
      this.initWebSocket();
    },
    //ws接收消息
    websocketonmessage(e) { //数据接收
      const redata = JSON.parse(e.data)
      this.getWSData(redata)
    },
    //ws发送
    websocketsend(Data) {
      this.websock.send(Data);
    },
    //ws关闭
    websocketclose(e) {  //关闭
      this.wsHeartflag = false;
      console.log('断开连接', e);
    },
    //心跳检测  每4.5分钟发送一次
    timingHeart() {
      if (this.wsHeartflag) {
        this.webSocketObj.send('奋进号，前进！');
      }
      this.wsHeart = setTimeout(() => {
        this.timingHeart();
      }, 100 * 1000); // 100s心跳
    },
    //销毁
    destroyed() {
      window.clearInterval(this.timer)
    },
  }
}
</script>

<style scoped>
#content {
  display: flex;
  /*border: solid royalblue 1px;*/
  height: 10vh;
  font-size: 1.25rem;
  align-items: center;
  justify-content: center;
}

.lfont {
  font-size: 1.25rem;
}

.bb {
  /*border: solid royalblue 1px;*/
}

.flexcenter {
  margin: 0 auto;
  /*align-items: center;*/
  /*justify-content: center;*/
}

</style>
